<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12">
        <SectionHeader :title="title" :subtitle="subtitle" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <span v-if="qastatus === 0 && editable">
          <b-button :disabled="!isQAAdmin" @click="assignOfficer()" class="float-left" variant="primary">Assign Officer</b-button>
        </span>
        <div v-else-if="(qastatus === 4 || qastatus === 6) && (isQAAdmin || isSuperAdmin)">
          <b-button :disabled="!(isQAAdmin || isSuperAdmin)" @click="assignGOfficer()" class="float-left" variant="primary">{{
            data.gnhrQa === null ? 'Assign Officer' : 'Reassign Officer'
          }}</b-button>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <hr />
      </div>
    </div>
    <div v-if="loadingHH || loadingMM">
      <div class="row" style="margin-top: 100px">
        <div class="col-lg col-md-6 col-sm-6 mb-4">
          <loader size="200" color="#007bff"></loader>
        </div>
      </div>
      <div class="row">
        <div class="col-lg col-md-6 col-sm-6 mb-4">
          <p class="text-center"><strong>Loading household members data..</strong></p>
        </div>
      </div>
    </div>
    <b-tabs v-else v-model="tabIndex" pills card content-class="mt-0" active-nav-item-class="font-weight-bold" fill vertical lazy>
      <b-tab v-for="(tab, i) in tabData" :key="i" :title-link-class="linkClass(i)">
        <template v-slot:title>
          <i class="material-icons" :style="getTabStyle(i)">{{ getTabIcon(i) }}</i> {{ getTabText(tab, i) }}
        </template>
        <b-card no-body style="padding-left: 10px; padding-right: 10px">
          <QAItem
            v-on:next="nextTab"
            v-on:done="evaluateChanges"
            :index="i"
            :editable="editable"
            :status-filter="qastatus"
            :data="tab.data"
            :members-reviewed.sync="membersReviewed"
            :is-household="tab.isHousehold"
            :valid-codes="tab.isHousehold ? hCodes : mCodes" />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import Loader from 'vue-spinner/src/ScaleLoader.vue'
import SectionHeader from '../containers/SectionHeader'
import surveyService from '../api/survey'
import QAItem from './QAItem'

Vue.use(require('vue-moment'))

export default {
  name: 'QAValidate',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    qastatus: {
      type: Number
    },
    enumerator: {
      type: String,
      default: 'Unknown'
    },
    officer: {
      type: String,
      default: 'Unassigned'
    },
    gofficer: {
      type: String,
      default: 'Unassigned'
    }
  },
  components: {
    Loader,
    QAItem,
    SectionHeader
  },
  watch: {
    data: {
      handler() {
        this.init()
      },
      deep: true
    },
    tabStatus: function () {
      this.membersReviewed = this.allReviewed()
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isGnhr: 'isGnhr',
      isSuperAdmin: 'isSuperAdmin',
      isOperations: 'isOperations',
      isQAAdmin: 'isQAadmin'
    }),
    title: function () {
      return is.empty(this.data.responder.trim()) ? 'Group ID: ' + this.data.id : this.data.responder
    },
    subtitle: function () {
      let go = this.isGnhr && this.data.gnhrQa != null ? '<br/> GNHR QA: ' + this.gofficer : ''
      let last = 'Enumerator: ' + this.enumerator + '<br /> QA Officer: ' + this.officer + go + '<br/> Registered: ' + this.data.regDate + '<br/> Last Updated: ' + this.data.updated
      return is.empty(this.data.responder.trim()) ? last : 'Group ID: ' + this.data.id + ' <br/> ' + last
    },
    validActionValues: function () {
      return this.isGnhr ? [16, 15, 18] : [1, 2, 4]
    }
  },
  data() {
    return {
      loadingHH: false,
      loadingMM: false,
      tabIndex: 0,
      tabData: [],
      tabStatus: [],
      tabStatusIcon: [],
      hCodes: [],
      mCodes: [],
      membersReviewed: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.tabData = []
      this.tabStatus = []
      this.tabStatusIcon = []
      this.tabStatus.push(this.data.qaStatus)
      this.getQuestionCodes()
    },
    getQuestionCodes() {
      let self = this
      surveyService
        .getQaQuestionCodes()
        .then((response) => {
          const hx = response.data[0].household.split(',')
          const mx = response.data[0].members.split(',')

          self.hCodes = hx.map((s) => s.trim())
          self.mCodes = mx.map((s) => s.trim())

          self.getHousehold()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getHousehold() {
      if (!this.loadingHH) {
        let self = this
        this.loadingHH = true
        this.tabData = []
        surveyService
          .getHousehold(this.data.id)
          .then((response) => {
            self.tabData.push({ isHousehold: true, data: response.data })
            self.loadingHH = false
            self.tabStatusIcon.push(response.data.has_errors)
            self.getMembers()
          })
          .catch((error) => {
            self.loadingHH = false
            this.handleError(error)
          })
      }
    },
    getMembers() {
      if (!this.loadingMM) {
        let self = this
        this.loadingMM = true
        surveyService
          .getHouseholdMembers({ household: this.data.id })
          .then((response) => {
            response.data.forEach(function (member) {
              self.tabData.push({ isHousehold: false, data: member })
              self.tabStatus.push(member.qaStatus)
              self.tabStatusIcon.push(member.has_errors)
            })
            self.membersReviewed = self.allReviewed()
            self.loadingMM = false
          })
          .catch((error) => {
            self.loadingMM = false
            this.handleError(error)
          })
      }
    },
    // Events
    assignOfficer() {
      this.$emit('assignOfficer')
    },
    assignGOfficer() {
      this.$emit('assignGOfficer')
    },
    markClear() {
      let self = this
      let iid = this.data.id
      this.$confirm('Marking as good. Continue?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          surveyService
            .updateHouseholdQA({ field: 'status', status: 16, groups: [iid] })
            .then(() => {
              self.refreshHouseholds()
            })
            .catch((error) => {
              self.handleError(error)
              return false
            })
          self.$message({ type: 'success', message: 'Update successful.' })
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: 'Update canceled'
          })
        })
    },
    // Helpers
    refreshHouseholds() {
      this.loadingHH = true
      this.$emit('refreshHH')
    },
    linkClass(idx) {
      let style = ['bg-primary', 'white']
      let defaultStyle = ['bg-light', 'text-info']
      let successStyle = ['bg-success', 'white']
      let dangerStyle = ['bg-danger', 'white']

      if (this.tabStatusIcon[idx] === true && this.tabIndex === idx) {
        return dangerStyle
      }

      switch (this.tabStatus[idx]) {
        case 1:
          style = this.tabIndex === idx ? style : defaultStyle
          break
        case 2:
        case 3:
        case 8:
        case 15:
          style = this.tabIndex === idx ? (this.tabStatusIcon[idx] === true ? dangerStyle : successStyle) : defaultStyle
          break
        case 4:
        case 5:
        case 6:
        case 7:
        case 13:
        case 14:
        case 16:
        case 18:
          style = this.tabIndex === idx ? successStyle : defaultStyle
          break
        default:
          style = this.tabIndex === idx ? ['bg-secondary', 'white'] : defaultStyle
      }
      return style
    },
    getTabStyle(idx) {
      if (this.tabIndex === idx) return 'color: white'

      if (this.tabStatusIcon[idx] === true) {
        return 'color: red'
      }

      let style = 'color: grey'
      switch (this.tabStatus[idx]) {
        case 1:
          style = 'color: grey'
          break
        case 2:
        case 3:
        case 8:
        case 15:
          style = this.tabStatusIcon[idx] === false ? 'color: green' : 'color: red'
          break
        case 4:
        case 5:
        case 6:
        case 7:
        case 13:
        case 14:
        case 18:
        case 16:
          style = 'color: green'
          break
        default:
          style = 'color: grey'
      }
      return style
    },
    getTabIcon(idx) {
      let icon = 'pending_actions'

      if (this.tabStatusIcon[idx] === true) {
        return 'error_outline'
      }

      switch (this.tabStatus[idx]) {
        case 1:
          icon = 'pending_actions'
          break
        case 2:
        case 3:
        case 8:
        case 15:
          icon = this.tabStatusIcon[idx] === false ? 'check_circle_outline' : 'error_outline'
          break
        case 4:
        case 5:
        case 6:
        case 7:
        case 13:
        case 14:
        case 16:
        case 18:
          icon = 'check_circle_outline'
          break
        case 9:
          icon = 'inbox'
          break
        case 11:
          icon = 'drafts'
          break
        case 12:
          icon = 'mark_email_red'
          break
        default:
          icon = 'pending_actions'
      }
      return icon
    },
    getTabText(tab, idx) {
      return tab.isHousehold ? 'Household' : this.getResponses(tab.data.responses, 'G1', 'Member') + ' ' + this.getResponses(tab.data.responses, 'G2', idx)
    },
    getResponses(responses, code, alt) {
      let x = responses
        .filter(function (r) {
          return r.code == code
        })
        .map(function (r) {
          return r.answer
        })
      return x.length > 0 ? x[0] : alt
    },
    evaluateChanges(payload) {
      // console.log(payload)
      let self = this
      if (payload.type === 'status_update') {
        Vue.set(this.tabStatus, payload.index, payload.status)
        if (payload.isHousehold) {
          let p = { field: 'status', status: payload.status, groups: [payload.id], reason: '' }
          if (is.not.undefined(payload.reason)) {
            p['reason'] = payload.reason
          }
          surveyService
            .updateHouseholdQA(p)
            .then(() => {
              if (payload.status === 4 || payload.status === 15 || payload.status === 16) {
                self.$emit('proceed', {})
              }
            })
            .catch((error) => {
              self.handleError(error)
              return false
            })
        } else {
          let p = { field: 'status', status: payload.status, groupMembers: [payload.id] }
          surveyService
            .updateHouseholdMemberQA(p)
            .then(() => {})
            .catch((error) => {
              self.handleError(error)
              return false
            })
        }
      } else if (payload.type === 'add_error') {
        Vue.set(this.tabStatus, payload.index, payload.errorStatus)
        Vue.set(this.tabStatusIcon, payload.index, true)
        delete payload['type']
        delete payload['index']
        delete payload['isHousehold']
        surveyService
          .addErrorItem(payload)
          .then(() => {})
          .catch((error) => {
            self.handleError(error)
            return false
          })
      } else if (payload.type === 'clear_error') {
        Vue.set(this.tabStatus, payload.index, payload.errorStatus)
        Vue.set(this.tabStatusIcon, payload.index, false)
        delete payload['type']
        delete payload['index']
        delete payload['isHousehold']
        surveyService
          .removeErrorItem(payload)
          .then(() => {})
          .catch((error) => {
            self.handleError(error)
            return false
          })
      } else if (payload.type === 'edit_question') {
        delete payload['type']
        delete payload['index']
        delete payload['isHousehold']
        surveyService
          .updateQuestion(payload)
          .then((r) => {
            self.$message({
              type: 'info',
              message: 'Question updated'
            })
          })
          .catch((error) => {
            self.$message({
              type: 'error',
              message: 'Cannot update response: ' + error.error
            })
            return false
          })
      }
    },
    allReviewed() {
      let reviewed = true
      for (let i in this.tabStatus) {
        if (i > 0 && is.not.inArray(this.tabStatus[i], [4, 5, 6, 7, 13, 14, 18, 16])) {
          reviewed = false
        }
      }
      return reviewed
    },
    nextTab() {
      this.tabIndex = this.tabIndex + 1 > this.tabData.length ? this.tabData.length : this.tabIndex + 1
    }
  }
}
</script>
