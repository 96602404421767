<template>
  <div>
    <b-form-group id="community_combo" description="" label="Community" label-class="font-weight-bolder" :label-cols="lpos" label-for="community_select" :state="null">
      <model-list-select id="community_select" name="community_select" :list="comms" v-model="selected" placeholder="Select Community" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'CommunityFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    districtId: { type: Number | String, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      options: 'getCommunitiesDropdown'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
    comms: function () {
      let self = this
      let seen = []
      let retVal = []
      let x = this.options.filter((o) => {
        return self.districtId == 0 || o.districtId == 0 || self.districtId === o.district
      })
      x.forEach((e) => {
        if (!seen.includes(e.id)) {
          retVal.push(e)
          seen.push(e.id)
        }
      })
      return retVal
    }
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    },
    districtId: function (v) {
      console.log('new DID', v)
      this.selected = 0
      // console.log('comms', this.comms.length)
      // console.log(this.comms)
    }
  },
  created() {
    this.selected = this.id
    // console.log('district ID', this.districtId)
    // console.log('options', this.options.length)
    // console.log('comms', this.comms.length)
    // console.log(this.comms[2])
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('community_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
