<template>
  <div>
      <b-form-group
        id="qa_combo"
        description=""
        label="qa"
        :label-cols="lpos"
        label-for="qa_select"
        :state="null">
          <template v-slot:label>
             <b>QA Officer</b>
          </template>
          <model-list-select id="qa_select" name="qa_select" :list="options" v-model="selected" placeholder="Select QA Officer" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'SelectQAOfficer',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    value: { type: Number, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    selected: null
  }),
  computed: {
    ...mapGetters({
      officers: 'getOfficerDropdown'
    }),
    options: function () {
      return this.officers.filter(function (o) {
        return o.id > 0
      })
    },
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    selected: function() {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.value
  },
  methods: {
    sendUpdate() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
