<template>
  <div>
    <b-form-group id="officer_combo" description="" :label="label" :label-cols="lpos" label-for="officer_select" label-class="font-weight-bolder" :state="null">
      <model-list-select id="officer_select" name="officer_select" :list="officers" v-model="selected" placeholder="Select Officer" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'OfficerFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    onlyGnhr: { type: Boolean, default: false },
    label: { type: String, default: 'Officer' },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      options: 'getOfficerDropdown'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
    officers: function () {
      let self = this
      return this.options.filter((e) => self.onlyGnhr === false || e.firm_id === 1)
    }
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created() {
    this.selected = this.id
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('qa_officer_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
