<template>
  <div style="margin: 0; padding: 0">
    <div class="row mt-4">
      <div class="col-lg col-md-3 col-sm-6 mb-3">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Households</span>
                <scale-loader v-if="stats['households'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['households'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Members</span>
                <scale-loader v-if="stats['members'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['members'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Spot Checked</span>
                <scale-loader v-if="stats['spotchecked'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['spotchecked'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">% Spot Checked</span>
                <scale-loader v-if="stats['perc_sc'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['perc_sc'].value }}%</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">% Matched</span>
                <scale-loader v-if="stats['perc_matched'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['perc_matched'].value }}%</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-7">
        <PageHeader :title="title" subtitle="Spot Check Responses" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row"></div>
            <div class="row mt-3">
              <div class="col-sm-2" v-if="isGnhr">
                <FirmFilter v-on:firm_change="filterByFirm" v-show="isGnhr" :onlySupervisedFirms="isGnhr && isQAAdmin" />
              </div>
              <div class="col-sm-2" v-if="isGnhr">
                <DistrictFilter v-on:district_change="filterByDistrict" />
              </div>
              <div class="col-sm-3">
                <EnumeratorFilter v-on:enumerator_change="filterByEnumerator" />
              </div>
              <div class="col-sm-3">
                <b-form-group label="Match?" label-cols-sm="3" label-align-sm="right" label-for="filter" label-class="font-weight-bolder">
                  <model-list-select
                    id="match_select"
                    name="match_select"
                    :list="[
                      { id: 'all', label: 'All' },
                      { id: 'errors', label: 'Do not match' }
                    ]"
                    v-model="filter"
                    placeholder="Select Match"
                    option-value="id"
                    option-text="label"
                  ></model-list-select>
                </b-form-group>
              </div>
            </div>
          </div>

          <div v-if="busy">
            <div class="row" style="margin-top: 100px">
              <div class="col-lg col-md-6 col-sm-6 mb-4">
                <scale-loader size="200" color="#007bff"></scale-loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg col-md-6 col-sm-6 mb-4">
                <p class="text-center"><strong>Loading from database...</strong></p>
              </div>
            </div>
          </div>

          <div v-else>
            <b-card no-body>
              <!-- question table -->
              <b-row>
                <b-col :sm="12">
                  <b-table
                    show-empty
                    responsive
                    striped
                    outlined
                    head-variant="dark"
                    style="overflow-y:scroll display:block"
                    :tbody-tr-class="rowClass"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-function="filterTable"
                    @filtered="onFiltered"
                    :fields="tableFields"
                    :items="tableItems"
                    :sticky-header="false"
                    class="table mb-3 th-class"
                  >
                    <template v-slot:empty>
                      <h4>No data found</h4>
                    </template>
                  </b-table>

                  <b-pagination v-model="currentPage" limit="20" pill :total-rows="totalRows" :per-page="perPage" align="center" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { ModelListSelect } from 'vue-search-select'
let numeral = require('numeral')

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import PageHeader from '../../containers/PageHeader'
import FirmFilter from '../../components/filters/FirmFilter'
import DistrictFilter from '../../components/filters/DistrictFilter'
import EnumeratorFilter from '../../components/filters/SupervisorEnumeratorFilter'

import surveyService from '../../api/survey'

Vue.use(require('vue-moment'))

export default {
  name: 'Spotcheck',
  components: {
    ScaleLoader,
    PageHeader,
    EnumeratorFilter,
    FirmFilter,
    DistrictFilter,
    ModelListSelect
  },
  data() {
    return {
      busy: true,
      // Table
      totalRows: 0,
      totalHHRows: 0,
      totalMMRows: 0,
      tableItems: [],
      tableFields: [
        {
          key: 'enumerator',
          label: 'Enumerator'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'code',
          label: 'Code'
        },
        {
          key: 'question',
          label: 'Question'
        },
        {
          key: 'response_firm',
          label: 'Firm Response'
        },
        {
          key: 'response_sc',
          label: 'Spot Check Response'
        },
        {
          key: 'match',
          label: 'Match'
        }
      ],
      // Pagination
      currentPage: 1,
      perPage: 50,
      // Filters
      filter: 'all',
      enumeratorFilter: 0,
      districtFilter: 0,
      firmFilter: '',
      search: '',
      sortProps: { order: 'ascending', prop: 'created' },
      queryProps: {
        sort: this.sortProps,
        search: '',
        qa_status: '',
        enumerator: '',
        district: ''
      },
      // Stats
      stats: {
        households: { id: 0, loading: true, ovalue: 0, value: 0 },
        members: { id: 0, loading: true, ovalue: 0, value: 0 },
        spotchecked: { id: 1, loading: true, ovalue: 0, value: 0 },
        perc_sc: { id: 2, loading: true, ovalue: 0, value: 0 },
        perc_matched: { id: 3, loading: true, ovalue: 0, value: 0 }
      }
    }
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      user: 'getUser',
      isQAAdmin: 'isQAadmin'
    }),
    title: function () {
      return 'Households (' + numeral(this.totalHHRows).format('0,0') + ') | Members (' + numeral(this.totalMMRows).format('0,0') + ')'
    }
  },
  created() {
    this.firmFilter = this.isGnhr ? 0 : this.user.firm_id
    this.get()
  },
  methods: {
    // API Call handlers
    get() {
      let self = this
      this.busy = true
      Object.keys(this.stats).forEach(function (x) {
        self.stats[x].loading = true
      })

      let params = this.getParams()
      surveyService
        .getSpotcheck(params)
        .then((response) => {
          self.tableItems = response.data
          self.totalHHRows = response.data.filter((e) => e.type === 'Household').length
          self.totalMMRows = response.data.filter((e) => e.type === 'Member').length
          self.totalRows = response.data.length

          self.stats['households'].value = self.cnumbers(response.meta['households'])
          self.stats['members'].value = self.cnumbers(response.meta['members'])
          self.stats['spotchecked'].value = self.cnumbers(response.meta['spotchecked'])
          self.stats['perc_sc'].value = self.to2dec(response.meta['perc_sc'])
          self.stats['perc_matched'].value = self.to2dec(response.meta['perc_matched'])

          self.busy = false
          Object.keys(this.stats).forEach(function (x) {
            self.stats[x].loading = false
          })
        })
        .catch((error) => {
          this.busy = false
          Object.keys(this.stats).forEach(function (x) {
            self.stats[x].loading = false
          })
          this.handleError(error)
        })
    },
    to2dec(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
    },
    // Event Handlers
    filterByFirm(v) {
      this.reset()
      this.firmFilter = v.value
      this.get()
    },
    filterByEnumerator(v) {
      this.reset()
      this.enumeratorFilter = v.value
      this.get()
    },
    filterByDistrict(v) {
      this.reset()
      this.districtFilter = v.value
      this.get()
    },
    filterTable(row, filterText) {
      if (filterText === 'all') return true

      return row.match === 'Yes' ? false : true
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // Helpers
    getParams() {
      let info = this.queryProps
      return {
        spotcheck: 0,
        use_sdate: 1,
        page: this.currentPage,
        limit: this.perPage,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : this.sortProps.prop,
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : this.sortProps.order,
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        enumerator: this.enumeratorFilter === 0 ? '' : this.enumeratorFilter,
        district: this.districtFilter === 0 ? '' : this.districtFilter,
        search: this.search === '' ? '' : this.search,
        version: 2
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.match === 'No') return 'table-danger'
    },
    reset(inclStats = true) {
      let self = this
      if (inclStats) {
        Object.keys(this.stats).forEach(function (x) {
          self.stats[x].value = 0
        })
      }
    }
  }
}
</script>
<style>
.scrollable-nav-tabs {
  flex-wrap: nowrap !important;
  white-space: nowrap;
  max-height: 800px;
  overflow: auto;
}
.text-light {
  color: white !important;
}
.th-class thead {
  border: 1px solid #212529;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
