<template>
  <div style="margin: 0; padding: 0">
    <div class="row">
      <div class="col">
        <div class="cad crd-small mb-4">
          <div class="card-body pb-3">
            <data-tables :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" :table-props="tableProps">
              <el-table-column v-for="(item, idx) in visibleFields" :key="idx" :prop="item.prop" :label="item.label" :sortable="item.sortable" :width="item.width">
                <template slot-scope="scope">
                  <span v-if="item.prop === 'facial' || item.prop === 'biographic' || item.prop === 'biometric'">
                    <b-badge v-if="scope.row[item.prop] == 0">Not checked</b-badge>
                    <b-badge v-else-if="scope.row[item.prop] == 1" variant="success">Not duplicate</b-badge>
                    <b-badge v-else variant="danger">Duplicate</b-badge>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
              <el-table-column align="right" label="" v-if="status !== 'Resolved'">
                <template slot-scope="scope">
                  <el-button size="mini" icon="el-icon-search" type="primary" title="Review" @click="review(scope.$index, scope.row)" :disabled="!isEditable()">Review</el-button>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </div>
    </div>

    <!-- Review Case -->
    <el-dialog :title="dialogTitle" :fullscreen="true" width="100%" :visible.sync="dialogVisible" :before-close="handleClose">
      <QADuplicateItem ref="adjudicator" :data="dialogData" v-on:proceed="next" />
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { DataTables } from 'vue-data-tables'
import is from 'is_js'

import QADuplicateItem from '../QADuplicateItem'

Vue.use(DataTables)
Vue.use(require('vue-moment'))

export default {
  name: 'QADuplicates',
  props: {
    status: { type: String, default: null },
    info: { type: Array, default: null }
  },
  components: {
    QADuplicateItem
  },
  data() {
    return {
      busy: true,
      dialogTitle: '',
      dialogVisible: false,
      dialogData: {},
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['group_id', 'primary_name']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No responses found',
        stripe: true,
        defaultSort: { order: 'ascending', prop: 'primary_name' }
      }
    }
  },
  created() {
    this.setTableFields()
    this.get()
  },
  watch: {
    info: function () {
      this.get()
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isAdmin: 'isAdmin',
      isSuperAdmin: 'isSuperAdmin'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    filename: function () {
      let x = ''
      switch (this.status) {
        case 'New':
          x = 'Duplicates - New.csv'
          break
        case 'In Progress':
          x = 'Duplicates - In Progress.csv'
          break
        case 'Resolved':
          x = 'Duplicates - Resolved.csv'
          break
        default:
          x = 'Duplicates.csv'
      }
      return x
    }
  },
  methods: {
    setTableFields() {
      // let utc = new Date().toJSON().slice(0, 10)
      // utc = is.empty(this.endDate) || is.null(this.endDate) || is.undefined(this.endDate) ? this.$moment(utc).format('MMM Do, YYYY') : this.$moment(this.endDate).format('MMM Do, YYYY')

      if (this.status === 'In Progress') {
        this.tableFields.push({ prop: 'group_id', label: 'HH_UUID', visible: true, sortable: true })
        this.tableFields.push({ prop: 'primary_name', label: 'HH Member', visible: true, sortable: true })
        this.tableFields.push({ prop: 'duplicate_name', label: 'Duplicate', visible: true, sortable: false })
        this.tableFields.push({ prop: 'matching_fields', label: 'Matching Fields', visible: true, sortable: true })
        this.tableFields.push({ prop: 'facial', label: 'Facial', visible: true, sortable: true, numeric: false })
        this.tableFields.push({ prop: 'biographic', label: 'Biographic', visible: true, sortable: true, numeric: false })
        this.tableFields.push({ prop: 'biometric', label: 'Biometric', visible: true, sortable: true, numeric: false })
      } else if (this.status === 'Resolved') {
        this.tableFields.push({ prop: 'group_id', label: 'HH_UUID', visible: true, sortable: true })
        this.tableFields.push({ prop: 'primary_name', label: 'HH Member', visible: true, sortable: true })
        this.tableFields.push({ prop: 'duplicate_name', label: 'Duplicate', visible: true, sortable: false })
        this.tableFields.push({ prop: 'matching_fields', label: 'Matching Fields', visible: true, sortable: true })
        this.tableFields.push({ prop: 'review_outcome', label: 'Outcome', visible: true, sortable: true })
        this.tableFields.push({ prop: 'review_comment', label: 'Comment', visible: true, sortable: false })
        this.tableFields.push({ prop: 'reviewer', label: 'Resolved By', visible: true, sortable: false })
        this.tableFields.push({ prop: 'reviewed_at', label: 'Resolved At', visible: true, date: true, sortable: false })
      } else {
        this.tableFields.push({ prop: 'group_id', label: 'HH_UUID', visible: true, sortable: true })
        this.tableFields.push({ prop: 'primary_name', label: 'HH Member', visible: true, sortable: true })
        this.tableFields.push({ prop: 'duplicate_name', label: 'Duplicate', visible: true, sortable: false })
        this.tableFields.push({ prop: 'matching_fields', label: 'Matching Fields', visible: true, sortable: true })
      }
    },
    get() {
      this.busy = true
      this.loadTable(this.info)
    },
    loadTable(response) {
      let self = this
      this.tableData = response.map(function (s) {
        s = self.getSimpleRow(s)
        return s
      })
      self.tableTotal = self.tableData.length
      self.busy = false
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && i.numeric === true ? this.cnumbers(x[i.prop]) : x[i.prop]
      }
      return x
    },
    isEditable() {
      return this.isAdmin || this.isSuperAdmin
    },
    review(idx, item) {
      this.modalIdx = idx
      if (is.not.undefined(item)) {
        this.dialogTitle = 'Review case for ' + item.primary_name + ' vs. ' + item.duplicate_name
        this.dialogData = item
        this.dialogVisible = true
      }
    },
    next() {
      let idx = this.modalIdx + 1 > this.tableData.length ? this.tableData.length : this.modalIdx + 1
      this.review(idx, this.tableData[idx])
    },
    previous() {
      let idx = this.modalIdx - 1 < 0 ? 0 : this.modalIdx - 1
      this.review(idx, this.tableData[idx])
    },
    handleClose(done) {
      if (is.function(done)) {
        done()
      }
      if (this.dialogVisible) {
        this.dialogVisible = false
      }
      this.$emit('update_tabs', {})
    }
  }
}
</script>
<style>
.el-table th > .cell {
  font-size: 11px !important;
  word-break: break-word;
}
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
