<template>
  <div>
      <b-form-group
        id="enumerator_combo"
        description=""
        label="Enumerator"
        label-class="font-weight-bolder"
        :label-cols="lpos"
        label-for="enumerator_select"
        :state="null">
          <model-list-select id="enumerator_select" name="enumerator_select" :list="options" v-model="selected" placeholder="Select Enumerator" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'SupervisorEnumeratorFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: '',
  }),
  computed: {
    ...mapGetters({
      options: 'getEnumeratorsAndSupervisors'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.id
  },
  methods: {
    sendUpdate () {
      if (!this.first) {
        this.$emit('enumerator_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
