<template>
  <div class="page-header row no-gutters mb-2">
    <div class="col-12 text-sm-left mb-0">
      <h5 class="page-title">{{ title }}</h5>
      <div class="text-uppercase page-subtitle mt-2" v-html="subtitle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' }
  }
}
</script>
<style>
.text-light {
  color: white !important;
}
</style>
