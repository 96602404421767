<template lang="pug">
extends ../BaseView.pug
</template>

<script>
import Vue from 'vue'
import BaseView from '../BaseView'

import Households from './Households'
import Spotcheck from './Spotcheck'
import Duplicates from './Duplicates'
import Officers from './Officers'
import Gofficers from './GnhrOfficers'

Vue.component('households', { template: '<Households />' })
Vue.component('duplicates', { template: '<Duplicates />' })
Vue.component('spotcheck', { template: '<Spotcheck />' })
Vue.component('officers', { template: '<Officers />' })
Vue.component('gofficers', { template: '<Gofficers />' })

export default {
  name: 'QualityAssurance',
  extends: BaseView,
  components: { Households, Officers, Gofficers, Duplicates, Spotcheck },
  props: {
    pages: {
      type: Array,
      default: () => {
        return [
          {
            tabName: 'households',
            component: 'households'
          },
          // {
          //   tabName: 'duplicates',
          //   component: 'duplicates'
          // },
          {
            tabName: 'spotcheck',
            component: 'spotcheck'
          },
          {
            tabName: 'officers',
            component: 'officers'
          },
          {
            tabName: 'gofficers',
            component: 'gofficers'
          }
        ]
      }
    }
  }
}
</script>
