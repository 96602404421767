<template>
  <div>
      <b-form-group
        id="qa_status_combo"
        description=""
        label="QA Status"
        :label-cols="lpos"
        label-for="qa_status_select"
        :state="null">
          <template v-slot:label>
             <b>{{ labelText }}</b>
          </template>
          <model-list-select id="qa_status_select" name="qa_status_select" :list="options" v-model="selected" placeholder="Select QA Status" option-value="id" option-text="label"></model-list-select>
      </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import is from 'is_js'

export default {
  name: 'QAStatusSelector',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: [Number, String], default: 0 },
    value: { type: Number, default: 0 },
    labelText: { type: String, default: 'QA Status' },
    labelPosition: { type: String, default: 'left' },
    validValues: { type: Array, default: () => { return [] } }
  },
  data: () => ({
    first: true,
    selected: null,
  }),
  computed: {
    ...mapGetters({
      stati: 'getQAStatus'
    }),
    options: function () {
      let self = this
      return this.stati.filter(function (s) {
        return self.validValues.length === 0 || is.inArray(s.id, self.validValues)
      })
    },
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
  },
  watch: {
    value: function (v) {
      this.selected = v
    },
    selected: function () {
      this.sendUpdate()
    }
  },
  created () {
    this.selected = this.id
  },
  methods: {
    sendUpdate () {
      if (this.value !== this.selected) {
        this.$emit('input', this.selected)
      }
    },
    updateVal (v) {
      this.selected = v
    }
  }
}
</script>
