<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="GNHR Officers Performance" :title="title" />

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-4" v-if="isGnhr">
                <FirmFilter label="Data collected by" v-on:firm_change="filterByFirm" />
              </div>
              <div class="col-sm-4" v-if="isGnhr">
                <RoleFilter v-on:role_change="filterByRole" :only-qa="true" />
              </div>
              <div class="col-sm-4">
                <div class="btn-group float-left" data-toggle="buttons" aria-label>
                  <el-input v-model="tableFilteredData[0].value" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 250px"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" :table-props="tableProps">
              <el-table-column v-for="(item, idx) in visibleFields" v-if="!busy" :key="idx" :prop="item.prop" :label="item.label" :sortable="item.sortable">
                <template slot-scope="scope">
                  <span v-if="item.prop === 'fix_rate'">
                    <b-badge v-if="scope.row[item.prop] < 90" variant="danger">{{ scope.row[item.prop] }}%</b-badge>
                    <b-badge v-else variant="success">{{ scope.row[item.prop] }}%</b-badge>
                  </span>
                  <span v-else-if="item.prop === 'error_rate'">
                    <b-badge v-if="scope.row[item.prop] < 2" variant="danger">{{ scope.row[item.prop] }}%</b-badge>
                    <b-badge v-else variant="success">{{ scope.row[item.prop] }}%</b-badge>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { DataTables } from 'vue-data-tables'
let numeral = require('numeral')

import PageHeader from '../../containers/PageHeader'
import FirmFilter from '../../components/filters/FirmFilter'
import RoleFilter from '../../components/filters/RoleFilter'

import userService from '../../api/user'

Vue.use(DataTables)
Vue.use(require('vue-moment'))

export default {
  name: 'GOfficers',
  components: {
    PageHeader,
    FirmFilter,
    RoleFilter
  },
  data() {
    return {
      busy: false,
      cutoff: 50,
      tableData: [],
      tableTotal: 0,
      firmFilter: 0,
      roleFilter: 0,
      busyHeader: [{ prop: 'busy', label: '', visible: true, sortable: false }],
      tableFields: [
        {
          prop: 'iUserId',
          label: 'ID',
          visible: false,
          sortable: 'custom'
        },
        { prop: 'fullname', label: 'Name', visible: true, sortable: true },
        { prop: 'role', label: 'Role', visible: true, sortable: true },
        {
          prop: 'num_households',
          label: 'Households',
          visible: true,
          sortable: true,
          numeric: true
        },
        {
          prop: 'in_qa',
          label: '# In QA',
          visible: true,
          numeric: true,
          sortable: true
        },
        {
          prop: 'ok',
          label: '# OK',
          visible: true,
          numeric: true,
          sortable: true
        },
        {
          prop: 'households_with_errors',
          label: '# Errors',
          visible: true,
          sortable: true,
          numeric: true
        },
        {
          prop: 'error_rate',
          label: 'Error Rate',
          visible: true,
          numeric: true,
          sortable: true
        },
        {
          prop: 'fix_rate',
          label: 'Clearance Rate',
          visible: true,
          numeric: true,
          sortable: true
        }
      ],
      tableFilteredData: [
        {
          value: '',
          prop: ['fullname', 'role']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No users found',
        stripe: true,
        defaultSort: { order: 'descending', prop: 'fix_rate' }
      }
    }
  },
  created() {
    this.get()
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    title: function () {
      return 'GNHR Officers (' + numeral(this.tableTotal).format('0,0') + ')'
    }
  },
  methods: {
    get() {
      let self = this
      this.busy = true
      this.tableData = []
      let params = this.getParams()
      userService
        .getGnhrOfficers(params)
        .then((response) => {
          self.procResults(response)
        })
        .catch((error) => {
          this.busy = false
          console.log(error)
          this.handleError(error)
        })
    },
    getParams() {
      return {
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        role: this.roleFilter === 0 ? '' : this.roleFilter,
        limit: -1
      }
    },
    filterByFirm(v) {
      this.firmFilter = v.value
      this.get()
    },
    filterByRole(v) {
      this.roleFilter = v.value
      this.get()
    },
    procResults(officers) {
      let self = this
      this.tableData = officers.map(function (v) {
        return self.getSimpleRow(v)
      })
      this.busy = false
      this.tableTotal = this.tableData.length
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) ? numeral(item[i.prop]).format('0,0') : x[i.prop]
      }
      return x
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
