<template>
  <div style="padding-top: 15px">
    <b-row v-if="statusFilter != 0" class="mb-4" align-h="center" align-v="center">
      <b-col sm="6" style="border: 0px solid green">
        <b-form-group label="Questions" label-cols-sm="3" label-align-sm="right" label-for="filter" class="mb-0 bold">
          <b-input-group size="sm">
            <b-form-select v-model="filter">
              <option value="all">All</option>
              <option value="errors">With Errors</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" style="border: 0px solid green">
        <!-- <div v-if="itemStatus === 16" style="margin-bottom: 1.4em; margin-top: 15px">
          <i class="material-icons" style="color: green; font-size: 24px">thumb_up</i> <span style="color: green; font-size: 24px"> GNHR cleared!</span>
        </div>
        <div v-else-if="itemStatus === 4 && !isGnhr" style="margin-bottom: 1.4em; margin-top: 15px">
          <i class="material-icons" style="color: green; font-size: 24px">thumb_up</i> <span style="color: green; font-size: 24px"> Firm cleared!</span>
        </div>
  -->
        <!-- <div v-else-if="isGnhr && isHousehold && editable" style="margin-bottom: 1.5em; margin-top: 15px">
          <b-button variant="danger" @click="handleAction('mark-correction')" style="margin-right: 20px">Mark as Correction</b-button>
          <b-button variant="success" @click="handleAction('mark-clear')">Mark as Clear</b-button>
        </div>
        <div v-else-if="!isGnhr" style="margin-bottom: 1.5em; margin-top: 15px">
          <b-button variant="primary" :disabled="doneDisabled" @click="handleAction('mark-done')">Mark done</b-button>
        </div> -->
        <!-- <div v-else></div> -->
      </b-col>
    </b-row>

    <!-- Pictures -->
    <b-row style="margin-left: 5px; margin-bottom: 20px">
      <b-col sm="5">
        <b-img :src="facialImage" fluid alt="image" />
      </b-col>
      <b-col sm="1"></b-col>
      <b-col sm="5">
        <b-row v-if="documents.length > 0">
          <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="320" img-height="320" style="text-shadow: 1px 1px 2px #333">
            <!-- Text slides with image -->
            <b-carousel-slide v-for="(d, idx) in documents" :key="idx + 'slide'" caption="" text="" :img-src="docuImage(d.profileId)"></b-carousel-slide>
          </b-carousel>
        </b-row>
        <b-row v-else>
          <b-carousel id="carousel-2" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="680" style="text-shadow: 1px 1px 2px #333">
            <b-carousel-slide caption="" img-blank img-alt="No documents">
              <p>No documents were found.</p>
            </b-carousel-slide>
          </b-carousel>
        </b-row>
      </b-col>
    </b-row>

    <!-- question table -->
    <b-row>
      <b-col :sm="12">
        <b-table
          show-empty
          responsive
          striped
          outlined
          head-variant="dark"
          style="overflow-y:scroll display:block"
          :tbody-tr-class="rowClass"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-function="filterTable"
          @filtered="onFiltered"
          :fields="fields"
          :items="tableItems"
          :sticky-header="false"
          class="table mb-3 th-class"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group v-for="(act, i) in rowActions()" :key="i">
              <b-button v-show="act.visible(row)" @click="act.handle(row)" :size="act.size" :variant="act.color" class="mr-1">{{ act.label }}</b-button>
            </b-button-group>
          </template>

          <template v-slot:empty>
            <h4>No data found</h4>
          </template>
        </b-table>

        <b-pagination v-model="currentPage" limit="20" pill :total-rows="totalRows" :per-page="perPage" align="center" size="sm"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'

Vue.use(require('vue-moment'))

export default {
  name: 'QADuplicateItem',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  watch: {
    membersReviewed: function (v) {
      this.allGood = v
    },
    data: {
      handler() {
        this.init()
      },
      deep: true
    },
    qa_status: function (v) {
      if (!this.firstTime) {
        this.updateStatus({ value: v })
      } else {
        this.firstTime = false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isGnhr: 'isGnhr',
      isQAAdmin: 'isQAadmin'
    }),
    fields: function () {
      let self = this
      return this.tableFields.filter(function (f) {
        return f.key === 'actions' ? self.actionVisible() : true
      })
    },
    validActionValues: function () {
      return this.isGnhr ? [16, 15] : [1, 2, 4]
    },
    doneDisabled: function () {
      return this.hasErrors()
    },
    facialImage: function () {
      return 'http://foms-api.mogcsp.gov.gh/photos/facial/' + this.itemId
    }
  },
  data() {
    return {
      slide: 0,
      firstTime: true,
      documents: [],
      itemId: null,
      itemStatus: null,
      qa_status: null,
      old_status: null,
      allGood: false,
      noErrorText: 'No error',
      currentPage: 1,
      perPage: 30,
      filter: 'all',
      totalRows: 0,
      tableData: [],
      tableItems: [],
      tableFields: [
        {
          key: 'code',
          label: 'Code'
        },
        {
          key: 'question',
          label: 'Question'
        },
        {
          key: 'response',
          label: 'Response'
        },
        {
          key: 'error',
          label: 'Error'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // console.log('Index: ' + this.index + '  Status: ' + this.data.qaStatus + ' all_reviewed: ' + (this.membersReviewed ? 'true' : 'false'))
      this.firstTime = true
      this.tableData = this.data
      this.itemId = this.isHousehold ? this.data.groupID : this.data.uuid
      this.documents = this.isHousehold ? [] : this.data.documents
      this.itemStatus = this.data.qaStatus
      this.qa_status = this.data.qaStatus
      this.old_status = this.data.qaStatus
      this.tableItems = this.getResponses(this.tableData.responses)
      this.totalRows = this.tableItems.length
      this.allGood = this.membersReviewed
    },
    getResponses(responses) {
      let self = this
      let validCodes = this.isHousehold
        ? [
            'UUID',
            'A1',
            'A2',
            'A3',
            'A4',
            'A5',
            'A9',
            'A10',
            'A10a',
            'A10b',
            'A12',
            'A13',
            'A14',
            'A16',
            'A16a',
            'A16b',
            'A16d',
            'A17b',
            'B23a',
            'C1',
            'C2',
            'C3',
            'C4',
            'C5',
            'C6',
            'C7',
            'C11a',
            'C11b',
            'D1a',
            'D1b',
            'D3ib'
          ]
        : [
            'UUID',
            'B1',
            'B2',
            'B3',
            'B4',
            'B5',
            'B7',
            'B8c',
            'B12g',
            'B14b',
            'B14c',
            'B16a',
            'B16e',
            'B17',
            'B19',
            'B19a',
            'B19b',
            'B21ai',
            'B21b',
            'B21c',
            'B21d1',
            'B21d2',
            'B22',
            'B22b',
            'B22c',
            'B22d1',
            'B23a',
            'B25',
            'B26a',
            'B26b',
            'G42',
            'G6',
            'G33d',
            'G38sr ',
            'B14a',
            'G12b',
            'G23',
            'G23a',
            'G21b',
            'G39a',
            'G43',
            'G33a',
            'G9',
            'G25c',
            'G22b',
            'G20a',
            'G28',
            'G12e',
            'B15',
            'B14c',
            'G29c',
            'G4a',
            'G28d2',
            'G2',
            'G12d',
            'G21',
            'G34',
            'G22c',
            'G28a',
            'G11',
            'UUID',
            'G13',
            'G33c',
            'G28d1',
            'G7',
            'G19',
            'G5',
            'G12g',
            'G29a',
            'G39b',
            'G6a',
            'G37b',
            'G10',
            'G38b',
            'G29',
            'G28c',
            'G8a',
            'G35',
            'G12a',
            'G22',
            'G3',
            'G28b',
            'G40',
            'G8b',
            'G21a',
            'G25b',
            'G12f',
            'G25',
            'G4',
            'G41',
            'B14b',
            'G29b',
            'G37a',
            'G20',
            'G12c',
            // 'G29d1',
            'G1'
          ]
      return responses
        .filter(function (r) {
          return is.inArray(r.code, validCodes)
        })
        .map(function (r) {
          let is_error = r.error !== self.noErrorText

          return {
            id: self.itemId,
            code: r.code,
            question: r.question,
            response: r.answer,
            error: r.error,
            is_error: is_error,
            error_id: r.errorID
          }
        })
        .sort(function (a, b) {
          return a.code.localeCompare(b.code, undefined, { numeric: true })
        })
    },
    handleAction(type, row, index) {
      if (type === 'reject') {
        this.rejectQuestion(row, index)
      } else if (type === 'clear-flag') {
        this.clearQuestion(row, index)
      } else if (type === 'mark-correction') {
        this.rejectHousehold()
      } else if (type === 'mark-clear') {
        this.clearHousehold()
      } else if (type === 'mark-done') {
        this.commitStatus(4)
      }
    },
    rejectQuestion(row, index) {
      let self = this
      let title = 'Provide reason for rejecting "' + row.question + '"'
      this.$prompt(title, 'Rejecting Question response', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^\s*\S+(?:\s+\S+){2,}\s*$/,
        inputErrorMessage: 'Reason must be 3 or more words'
      })
        .then((value) => {
          if (value.value !== '') {
            self.tableItems[index].error = value.value
          }
          self.tableItems[index].is_error = true

          self.$emit('done', {
            type: 'add_error',
            index: self.index,
            isHousehold: self.isHousehold,
            source: self.itemId,
            code: row.code,
            descriptions: row.question,
            response: row.response,
            addInfo: value.value,
            qaUser: is.null(self.data.qaUser) ? self.user.userID : self.tableData.qaUser,
            errorStatus: self.isGnhr ? 15 : 2
          })
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: 'Rejection canceled'
          })
        })
    },
    clearQuestion(row, index) {
      let self = this
      self
        .$confirm('Clear error on question. Continue?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
        .then(() => {
          let item = {}
          self.tableItems.forEach(function (q, i) {
            if (q.code === row.code) {
              item = q
              index = i
            }
          })
          item.error = self.noErrorText
          item.is_error = false
          Vue.set(self.tableItems, index, item)

          let has_errors = self.hasErrors()
          self.itemStatus = has_errors ? self.old_status : self.old_status === 15 ? 4 : 1

          self.$emit('done', {
            type: 'clear_error',
            index: self.index,
            isHousehold: self.isHousehold,
            source: self.itemId,
            errorStatus: has_errors ? self.old_status : self.old_status === 15 ? 4 : 1,
            errorID: row.error_id
          })
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: 'Clearing canceled'
          })
        })
    },
    rejectHousehold() {
      let self = this
      let title = 'Provide reason for rejecting this household'
      this.$prompt(title, 'Rejecting household', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^\s*\S+(?:\s+\S+){2,}\s*$/,
        inputErrorMessage: 'Reason must be 3 or more words'
      })
        .then((value) => {
          const reason = value.value === '' ? 'No reason given' : value.value
          const newStatus = 15
          self.$emit('done', {
            type: 'status_update',
            index: self.index,
            isHousehold: self.isHousehold,
            id: self.itemId,
            status: newStatus,
            reason: reason
          })
          self.itemStatus = newStatus
          self.old_status = newStatus
          self.$message({ type: 'success', message: 'Update successful.' })
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: 'Rejection canceled'
          })
        })
    },
    clearHousehold() {
      let self = this
      if (this.hasErrors()) {
        this.$message({
          duration: 6000,
          type: 'error',
          message: 'Update failed. Responses still have errors. To mark as clear, please clear all error flags.'
        })
      } else {
        this.$confirm('Mark household as clear. Continue?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
          .then(() => {
            const newStatus = 16
            self.$emit('done', {
              type: 'status_update',
              index: self.index,
              isHousehold: self.isHousehold,
              id: self.itemId,
              status: newStatus
            })
            self.itemStatus = newStatus
            self.old_status = newStatus
          })
          .catch(() => {
            self.$message({
              type: 'info',
              message: 'Clearing canceled'
            })
          })
      }
    },
    updateStatus(args) {
      let self = this
      if (args.value === 4 && !this.allGood) {
        this.qa_status = self.old_status
        if (is.not.undefined(this.$refs.sqas)) {
          this.$refs.sqas.updateVal(self.old_status)
        }
        this.$message({
          duration: 6000,
          type: 'error',
          message: 'Update failed. Members have not been cleared. To mark as Reviewed, please clear all error flags on members.'
        })
      } else if (args.value === 4 && this.hasErrors()) {
        this.qa_status = self.old_status
        if (is.not.undefined(this.$refs.sqas)) {
          this.$refs.sqas.updateVal(self.old_status)
        }
        this.$message({
          duration: 6000,
          type: 'error',
          message: 'Update failed. Responses still have errors. To mark as Reviewed, please clear all error flags.'
        })
      } else if (args.value === 2 && !this.hasErrors() && this.allGood) {
        this.qa_status = self.old_status
        if (is.not.undefined(this.$refs.sqas)) {
          this.$refs.sqas.updateVal(self.old_status)
        }
        this.$message({
          duration: 6000,
          type: 'error',
          message: 'Update failed. Please indicate which responses (including member responses) have issues before updating status.'
        })
      } else if (args.value !== this.itemStatus) {
        this.commitStatus(args.value)
      }
    },
    commitStatus(newStatus) {
      let self = this
      this.$confirm('Update status. Continue?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          self.$emit('done', {
            type: 'status_update',
            index: self.index,
            isHousehold: self.isHousehold,
            id: self.itemId,
            status: newStatus
          })
          self.itemStatus = newStatus
          self.old_status = newStatus
          self.$message({ type: 'success', message: 'Update successful.' })
        })
        .catch(() => {
          if (is.not.undefined(self.$refs.sqas)) {
            self.$refs.sqas.updateVal(self.old_status)
          }
          self.$message({
            type: 'info',
            message: 'Update canceled'
          })
        })
    },
    // Helpers
    actionVisible() {
      if (this.isGnhr) {
        return this.itemStatus === 4
      }
      return this.itemStatus !== 16 && ((this.itemStatus < 5 && this.itemStatus !== 4) || (this.itemStatus === 15 && this.isQAAdmin))
    },
    docuImage(i) {
      return 'http://foms-api.mogcsp.gov.gh/photos/document/' + i
    },
    hasErrors() {
      let y = this.tableItems.filter(function (x) {
        return x.is_error
      })
      return y.length > 0
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.error !== this.noErrorText) return 'table-danger'
    },
    filterTable(row, filterText) {
      if (filterText === 'all') return true

      return row.error === this.noErrorText ? false : true
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowActions() {
      let self = this
      return [
        {
          label: 'Reject',
          size: 'sm',
          color: 'danger',
          visible: function (row) {
            let v = false
            switch (self.itemStatus) {
              case 1:
                v = !row.item.is_error
                break
              case 2:
              case 3:
              case 4:
                v = self.isGnhr
                break
              case 8:
                v = !row.item.is_error
                break
              default:
                v = false
            }
            return v
          },
          handle: function (row) {
            self.handleAction('reject', row.item, row.index)
          }
        },
        // { label: 'Approve Error Flag',
        //   size: 'sm',
        //   color: 'success',
        //   visible: function (row) {
        //     let v = false
        //     switch(self.tableData.qa_status) {
        //       case 1:
        //         v = self.isQAAdmin && row.item.is_error
        //         break
        //       default:
        //         v = false
        //     }
        //     return v
        //   },
        //   handle: function (row) {
        //     self.handleAction('approve-flag', row.item, row.index)
        //   }
        // },
        {
          label: 'Clear Flag',
          size: 'sm',
          color: 'light',
          visible: function (row) {
            let v = false
            switch (self.itemStatus) {
              case 1:
                v = row.item.is_error
                break
              case 2:
              case 3:
              case 8:
                v = self.isQAAdmin && row.item.is_error
                break
              case 15:
              case 16:
                v = self.isQAAdmin && !self.isGnhr && row.item.is_error
                break
              default:
                v = false
            }
            return v
          },
          handle: function (row) {
            self.handleAction('clear-flag', row.item, row.index)
          }
        }
      ]
    }
  }
}
</script>
<style>
.th-class thead {
  border: 1px solid #212529;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
