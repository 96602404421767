<template>
  <div style="margin: 0; padding: 0">
    <div class="row mt-4">
      <div class="col-lg col-md-4 col-sm-6 mb-3" @click="loadTab({ value: 0 })">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">New</span>
                <scale-loader v-if="stats['new'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['new'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-4 col-sm-6 mb-3" @click="loadTab({ value: 1 })">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">In Progress</span>
                <scale-loader v-if="stats['inprogress'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['inprogress'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-4 col-sm-6 mb-3" @click="loadTab({ value: 2 })">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Resolved</span>
                <scale-loader v-if="stats['resolved'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['resolved'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-7">
        <PageHeader subtitle="Duplicate Cases" :title="title" />
      </div>
      <div class="col-sm-5 float-right mt-4" style="border: 0px solid red">
        <b-button @click="csvExport" class="float-right" variant="outline-primary" :disabled="tableData.length <= 0">
          <i class="material-icons">cloud_download</i> Download Duplicates
          <b-spinner small variant="primary" v-show="csvLoading"></b-spinner>
        </b-button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-8">
                <div class="btn-group float-left" data-toggle="buttons" aria-label>
                  <el-input v-model="search" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 300px"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <div v-if="loading">
              <div class="row" style="margin-top: 100px">
                <div class="col-lg col-md-6 col-sm-6 mb-4">
                  <scale-loader size="200" color="#007bff"></scale-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-lg col-md-6 col-sm-6 mb-4">
                  <p class="text-center"><strong>Loading from database...</strong></p>
                </div>
              </div>
            </div>
            <b-tabs v-else v-model="tabIndex" card pills fill>
              <b-tab :title="newTitle">
                <div class="row">
                  <div class="col-lg col-md-12 col-sm-12 mb-4">
                    <QADuplicates v-on:update_tabs="init" status="New" :info.sync="newList" />
                  </div>
                </div>
              </b-tab>
              <b-tab :title="progressTitle">
                <div class="row">
                  <div class="col-lg col-md-12 col-sm-12 mb-4">
                    <QADuplicates v-on:update_tabs="init" status="In Progress" :info.sync="progressList" />
                  </div>
                </div>
              </b-tab>
              <b-tab :title="resolvedTitle">
                <div class="row">
                  <div class="col-lg col-md-12 col-sm-12 mb-4">
                    <QADuplicates v-on:update_tabs="init" status="Resolved" :info.sync="resolvedList" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import is from 'is_js'
import { DataTables } from 'vue-data-tables'

import PageHeader from '../../containers/PageHeader'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import QADuplicates from '../../components/tables/QADuplicates'

import surveyService from '../../api/survey'

Vue.use(DataTables)
Vue.use(require('vue-moment'))

let numeral = require('numeral')

export default {
  name: 'Duplicates',
  components: {
    PageHeader,
    ScaleLoader,
    QADuplicates
  },
  data() {
    return {
      search: '',
      tabIndex: 0,
      newList: [],
      progressList: [],
      resolvedList: [],
      newLoaded: false,
      progressLoaded: false,
      resolvedLoaded: false,
      stats: {
        new: { loading: true, value: 'New' },
        inprogress: { loading: true, value: 'In Progress' },
        resolved: { loading: true, value: 'Resolved' }
      },
      tableData: [],
      tableFields: [
        { prop: 'group_id', label: 'HH_UUID', visible: true },
        { prop: 'primary_name', label: 'HH Member', visible: true },
        { prop: 'duplicate_name', label: 'Duplicate', visible: true },
        { prop: 'matching_fields', label: 'Matching Fields', visible: true },
        { prop: 'status', label: 'Status', visible: true },
        { prop: 'created_at', label: 'Date Dectected', visible: true, date: true }
      ],
      tableTotal: 0,
      csvLoading: false
    }
  },
  created() {
    this.init()
  },
  computed: {
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    title: function () {
      return 'Duplicates (' + numeral(this.tableTotal).format('0,0') + ')'
    },
    newTitle: function () {
      return 'New (' + numeral(this.newList.length).format('0,0') + ')'
    },
    progressTitle: function () {
      return 'In Progress (' + numeral(this.progressList.length).format('0,0') + ')'
    },
    resolvedTitle: function () {
      return 'Resolved (' + numeral(this.resolvedList.length).format('0,0') + ')'
    },
    loading() {
      return !(this.newLoaded && this.progressLoaded && this.resolvedLoaded)
    }
  },
  watch: {
    search: function (v) {
      if (v == '' || v.length > 3) {
        this.loadData(v)
      }
    }
  },
  methods: {
    init() {
      this.loadData()
      this.loadStatsData()
    },
    loadStatsData() {
      const self = this
      Object.keys(this.stats).forEach(function (x) {
        self.stats[x].loading = true
        surveyService
          .getDuplicateStats({ status: self.stats[x].value })
          .then((i) => {
            let total = i.data['total']
            self.stats[x].loading = false
            self.stats[x].value = self.cnumbers(total)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    loadData(search = '') {
      const self = this
      const types = ['New', 'In Progress', 'Resolved']
      let params = {
        search: search
      }
      ;(this.tableData = []), (this.tableTotal = 0)
      this.newLoaded = false
      this.progressLoaded = false
      this.resolvedLoaded = false
      types.forEach(function (t) {
        params['status'] = t
        surveyService
          .getDuplicates(params)
          .then((response) => {
            return self.setList(t, response)
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
            self.setList(t, { data: [], meta: { total: 0 } })
          })
      })
    },
    setList(type, response) {
      if (type === 'New') {
        this.newList = response.data
        this.newLoaded = true
        this.tableTotal += response.meta.total
        this.setTableData(response.data)
      } else if (type === 'In Progress') {
        this.progressList = response.data
        this.progressLoaded = true
        this.tableTotal += response.meta.total
        this.setTableData(response.data)
      } else if (type === 'Resolved') {
        this.resolvedList = response.data
        this.resolvedLoaded = true
        this.tableTotal += response.meta.total
        this.setTableData(response.data)
      }
    },
    setTableData(v) {
      this.tableData = this.tableData.concat(v)
    },
    loadTab(v) {
      this.tabIndex = v.value
      this.search = ''
    },
    csvExport() {
      let self = this
      this.csvLoading = true
      let csvData = this.tableData.map(function (s) {
        s = self.getSimpleRow(s)
        return s
      })

      let csvContent = 'data:text/csv;charset=utf-8'
      for (let t in this.visibleFields) {
        csvContent += ',' + this.visibleFields[t].label
      }
      csvContent += '\n'
      csvContent += csvData
        .map(function (d) {
          let x = ''
          for (let y in d) {
            let u = d[y].replace(/,/g, '')
            x += u + ','
          }
          return x
        })
        .join('\n')
        .replace(/(^\{)|(\}$)/gm, '')
      let utc = this.$moment(new Date().toJSON().slice(0, 10)).format('YYYY-MM-DD')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', utc + ' QA Duplicates.csv')
      document.body.appendChild(link)
      link.click()

      this.csvLoading = false
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && i.numeric === true ? this.cnumbers(x[i.prop]) : x[i.prop]
      }
      return x
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
