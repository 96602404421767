<template>
  <div>
    <b-form-group id="qa_status_combo" :label="labelText" label-cols="auto" label-class="font-weight-bolder" label-for="qa_status_select">
      <model-list-select id="qa_status_select" name="qa_status_select" :list="options" v-model="selected" placeholder="Select Status" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import is from 'is_js'

export default {
  name: 'QAStatusDDFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: [Number, String], default: 0 },
    labelText: { type: String, default: 'QA Status' },
    labelPosition: { type: String, default: 'left' },
    validValues: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    first: true,
    selected: null,
    stati: [
      { id: 0, sid: [0], label: 'Unassigned' },
      { id: 1, sid: [1], label: 'Pending' },
      { id: 2, sid: [2, 3, 8, 10], label: 'Firm Rejected' },
      { id: 3, sid: [15], label: 'GNHR Rejected' },
      { id: 4, sid: [4, 11, 9], label: 'Firm OK' },
      { id: 6, sid: [18], label: 'GNHR Pending' },
      { id: 5, sid: [16], label: 'GNHR OK' }
    ]
  }),
  computed: {
    options: function () {
      let self = this
      return this.stati.filter(function (s) {
        // console.log(s)
        // console.log(self.validValues)
        return self.validValues.length === 0 || is.inArray(s.id, self.validValues)
      })
    },
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    }
  },
  watch: {
    id: function (v) {
      this.selected = v
    },
    selected: function () {
      this.sendUpdate()
    }
  },
  created() {
    this.selected = this.id
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('qa_status_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
