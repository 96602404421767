<template>
  <div style="margin: 0; padding: 0">
    <div class="row mt-4">
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 0 })" v-if="!(isGnhr && isQAOfficer)">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Unassigned</span>
                <scale-loader v-if="stats['unassigned'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['unassigned'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 1 })" v-show="!(isGnhr && isQAOfficer)">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Pending</span>
                <scale-loader v-if="stats['pending'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['pending'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 2 })" v-show="!(isGnhr && isQAOfficer)">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Firm Rejected</span>
                <scale-loader v-if="stats['firm_rejected'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['firm_rejected'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 4 })">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">Firm OK</span>
                <scale-loader v-if="stats['firm_ok'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['firm_ok'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 6 })" v-if="isGnhr">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">GNHR Pending</span>
                <scale-loader v-if="stats['gnhr_pending'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['gnhr_pending'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 3 })" v-if="isGnhr">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">GNHR Rejected</span>
                <scale-loader v-if="stats['gnhr_rejected'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['gnhr_rejected'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-3 col-sm-6 mb-3" @click="filterByStatus({ value: 5 })" v-if="isGnhr">
        <div class="stats-small stats-small--1 card card-small">
          <div class="card-body p-0 d-flex">
            <div class="d-flex flex-column m-auto">
              <div class="stats-small__data text-center">
                <span class="stats-small__label text-uppercase">GNHR Ok</span>
                <scale-loader v-if="stats['gnhr_ok'].loading" size="50" color="#dedede"></scale-loader>
                <h6 v-else class="stats-small__value count my-3">{{ stats['gnhr_ok'].value }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-7">
        <PageHeader :title="title" subtitle="Survey Responses" />
      </div>

      <div class="col-sm-5 float-right mt-4" style="border: 0px solid red">
        <b-button @click="csvExport" class="float-right" variant="outline-primary" :disabled="errLen <= 0">
          <i class="material-icons">cloud_download</i> Download Errors
          <b-spinner small variant="primary" v-show="csvLoading"></b-spinner>
        </b-button>
        <el-dropdown @command="handleErrorAction" class="mr-4 float-right" v-if="isGnhr">
          <el-button type="primary">Error Upload <i class="el-icon-arrow-down el-icon--right"></i> </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="upload"> <i class="material-icons">cloud_upload</i>Upload file</el-dropdown-item>
            <el-dropdown-item command="download"> <i class="material-icons">cloud_download</i> Download template</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row"></div>
            <div class="row mt-3">
              <div class="col-sm-4">
                <QAStatusFilter :id="statusFilter" :valid-values="stati" v-on:qa_status_change="filterByStatus" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4">
                <RegionFilter v-on:region_change="filterByRegion" />
              </div>
              <div class="col-sm-4">
                <DistrictFilter v-on:district_change="filterByDistrict" :region-id="regionFilter" />
              </div>
              <div class="col-sm-4">
                <CommunityFilter v-on:community_change="filterByCommunity" :district-id="districtFilter" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4" v-if="isGnhr">
                <FirmFilter v-on:firm_change="filterByFirm" v-show="isGnhr" :onlySupervisedFirms="isGnhr && isQAAdmin" />
              </div>
              <div class="col-sm-4">
                <EnumeratorFilter v-on:enumerator_change="filterByEnumerator" />
              </div>
              <div class="col-sm-4" v-if="(isGnhr && (isSuperAdmin || isQAAdmin) && statusFilter > 3) || (!isGnhr && isAdmin)">
                <OfficerFilter v-on:qa_officer_change="filterByOfficer" />
              </div>
            </div>
            <div class="row mt-3" v-if="statusFilter === 5">
              <div class="col-sm-8">
                <el-input v-model="searchFilter" placeholder="Search household ID or household head name" prefix-icon="el-icon-search" size="medium" style="idth: 250px"></el-input>
              </div>
            </div>
          </div>

          <div v-if="busy">
            <div class="row" style="margin-top: 100px">
              <div class="col-lg col-md-6 col-sm-6 mb-4">
                <scale-loader size="200" color="#007bff"></scale-loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg col-md-6 col-sm-6 mb-4">
                <p class="text-center"><strong>Loading from database...</strong></p>
              </div>
            </div>
          </div>

          <div v-else>
            <b-card no-body>
              <b-tabs v-model="activeTab" v-on:activate-tab="tabsClicked" class="py-4" nav-class="scrollable-nav-tabs" pills card vertical lazy>
                <template v-slot:tabs-start>
                  <div class="row">
                    <div class="col">
                      <el-dropdown @command="handleBulkAction" class="mb-4" v-if="showBulk">
                        <el-button size="small" type="primary">
                          Bulk Action
                          <i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-if="statusFilter == 0 || statusFilter == 1" command="assign">{{ statusFilter == 0 ? 'Assign' : 'Reassign' }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>

                      <el-dropdown @command="handleBulkAction" class="mb-4" v-if="showGBulk">
                        <el-button size="small" type="primary">
                          Bulk Action
                          <i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-if="statusFilter == 4 || statusFilter == 6" command="assignGnhrQA">{{ statusFilter == 4 ? 'Assign' : 'Reassign' }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>

                      <el-dropdown @command="handleFilterAction" class="mb-4" v-if="showErrorFilter">
                        <el-button size="small" type="primary">
                          {{ listFilter == 'all' ? 'Filter: All' : 'Filter: Households with Errors' }}
                          <i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="all">Show All</el-dropdown-item>
                          <el-dropdown-item command="errors">Show Households with Errors</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>

                      <div class="row mb-4">
                        <div class="col">
                          <strong class="text-center" style="font-size: 11px; font-weight: bold">{{ pageText }} households</strong>
                          <b-pagination
                            class="mt-1"
                            v-on:change="movePage"
                            v-model="currentPage"
                            :limit="3"
                            :total-rows="totalRows"
                            :per-page="pageSize"
                            aria-controls="household-tabs"
                            size="sm"></b-pagination>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="showBulk || showGBulk">
                        <div class="col px-4">
                          <b-form-checkbox id="selectAllCB" v-model="selectAll" name="selectAllCB" value="true" unchecked-value="false">
                            <strong class="text-center" style="font-size: 11px; font-weight: bold"> Select all</strong>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <b-tab id="household-tabs" v-for="(t, idx) in visibleTabs" :key="idx" :title="t.name" v-on:click="tabClicked">
                  <template v-slot:title>
                    <div v-if="showBulk || showGBulk">
                      <b-form-checkbox :id="'checkbox-' + idx" v-model="t.selected" :name="'checkbox-' + idx" value="true" unchecked-value="false">{{ t.name }}</b-form-checkbox>
                    </div>
                    <div v-else>{{ t.name }}</div>
                  </template>

                  <QAValidate
                    ref="validator"
                    v-on:refreshHH="get"
                    v-on:assignOfficer="assignOfficer(idx)"
                    v-on:assignGOfficer="assignGOfficer(idx)"
                    v-on:proceed="nextHousehold"
                    :data="t"
                    :enumerator="getEnumerator(t)"
                    :officer="getOfficer(t)"
                    :gofficer="getGOfficer(t)"
                    :editable="canEdit"
                    :qastatus="statusFilter"
                    :index="idx" />
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <!-- Assign to QA Officer -->
    <el-dialog title="Assign" :visible.sync="assignDialogVisible" width="30%">
      <SelectQAOfficer :value="assign_officer_id" v-model="assign_officer_id" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="assignDialogVisible = false">Cancel</b-button>
        <b-button size="sm" class variant="primary" :disabled="officerAssignDisabled" @click="doOfficerAssign()">Assign</b-button>
      </span>
    </el-dialog>

    <!-- Assign to QA Officer -->
    <el-dialog title="Assign" :visible.sync="assignGQADialogVisible" width="30%">
      <SelectQAOfficer :value="assign_gofficer_id" v-model="assign_gofficer_id" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="assignGQADialogVisible = false">Cancel</b-button>
        <b-button size="sm" class variant="primary" :disabled="gofficerAssignDisabled" @click="doGOfficerAssign()">Assign</b-button>
      </span>
    </el-dialog>

    <!-- Upload error modal -->
    <el-dialog width="40%" title="Upload Errors" :close-on-click-modal="false" :visible.sync="errorDialogVisible" :before-close="handleErrorClose">
      <vue-csv-import
        v-model.sync="bulkErrorCsv"
        :url="errorUploadUrl"
        :callback="uploadErrorSuccess"
        :catch="uploadErrorFailed"
        :map-fields="bulkErrorFields"
        :headers="true"
        loadBtnText="Upload"
        submitBtnText="Submit"></vue-csv-import>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleErrorClose()">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { VueCsvImport } from 'vue-csv-import'
import is from 'is_js'
let numeral = require('numeral')

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import PageHeader from '../../containers/PageHeader'
import FirmFilter from '../../components/filters/FirmFilter'
import RegionFilter from '../../components/filters/RegionFilter'
import DistrictFilter from '../../components/filters/DistrictFilter'
import CommunityFilter from '../../components/filters/CommunityFilter'
import EnumeratorFilter from '../../components/filters/SupervisorEnumeratorFilter'
import OfficerFilter from '../../components/filters/OfficerFilter'
import QAStatusFilter from '../../components/filters/QAStatusDDFilter'
import SelectQAOfficer from '../../components/selectors/SelectQAOfficer'
import QAValidate from '../../components/QAValidate'
import { EndPoints } from '../../api/api'

import surveyService from '../../api/survey'

Vue.use(require('vue-moment'))

export default {
  name: 'Households',
  components: {
    ScaleLoader,
    PageHeader,
    OfficerFilter,
    EnumeratorFilter,
    FirmFilter,
    RegionFilter,
    DistrictFilter,
    CommunityFilter,
    QAStatusFilter,
    SelectQAOfficer,
    QAValidate,
    VueCsvImport
  },
  data() {
    return {
      // Tabs display
      busy: false,
      errorDialogVisible: false,
      errorUploadUrl: '',
      canUpdateBulk: true,
      bulkErrorFields: {
        code: 'Code',
        source: 'Source',
        descriptions: 'Description',
        addInfo: 'Additional Info',
        response: 'Response',
        errorDate: 'Error Date'
      },
      bulkErrorCsv: [],
      selectAll: false,
      tabs: [],
      activeTab: 0,
      totalRows: 0,
      currentPage: 1,
      pageSize: 150,
      seen: [],
      errLen: 0,
      household: 0,
      listFilter: 'all',
      // Query members
      enumeratorFilter: 0,
      regionFilter: 0,
      districtFilter: 0,
      communityFilter: 0,
      officerFilter: 0,
      statusFilter: 1,
      firmFilter: '',
      searchFilter: '',
      qa_status_list: [
        { id: 0, sid: [0], label: 'Unassigned' },
        { id: 1, sid: [1], label: 'Pending' },
        { id: 2, sid: [2, 3, 8, 10], label: 'Firm Rejected' },
        { id: 3, sid: [15], label: 'GNHR Rejected' },
        { id: 4, sid: [4, 11, 9], label: 'Firm OK' },
        { id: 5, sid: [16], label: 'GNHR OK' },
        { id: 6, sid: [18], label: 'GNHR Pending' }
      ],
      sortProps: { order: 'ascending', prop: 'created' },
      queryProps: {
        sort: this.sortProps,
        search: '',
        qa_status: '',
        enumerator: '',
        district: ''
      },
      // Status
      stats: {
        unassigned: { id: 0, loading: true, value: 0 },
        pending: { id: 1, loading: true, value: 0 },
        firm_rejected: { id: 2, loading: true, value: 0 },
        firm_ok: { id: 4, loading: true, value: 0 },
        gnhr_rejected: { id: 3, loading: true, value: 0 },
        gnhr_pending: { id: 6, loading: true, value: 0 },
        gnhr_ok: { id: 5, loading: true, value: 0 }
      },
      // Office assign members
      assign_officer_id: 0,
      assignDialogVisible: false,
      assign_gofficer_id: 0,
      assignGQADialogVisible: false,
      // Validate data
      validateData: { errors: [], hhID: 0 },
      // CSV Export
      csvData: [],
      csvLoading: false
    }
  },
  created() {
    this.errorUploadUrl = EndPoints.QA_ERRORS_UPLOAD_URL
    this.firmFilter = this.isGnhr ? 0 : this.user.firm_id
    this.statusFilter = this.isQAOfficer ? 1 : 0
    this.get()
    this.loadStatsData()
  },
  watch: {
    selectAll: function (v) {
      // console.log('Selected: ' + v)
      this.setSelected(v)
    },
    searchFilter: function (v) {
      if (v.length > 4) {
        this.reset(false)
        this.get()
      }
    }
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      isSuperAdmin: 'isSuperAdmin',
      isAdmin: 'isAdmin',
      user: 'getUser',
      isQAAdmin: 'isQAadmin',
      isQAOfficer: 'isQAofficer'
    }),
    title: function () {
      return this.statusName + ' Households (' + numeral(this.totalRows).format('0,0') + ')'
    },
    statusName: function () {
      let v = ''
      for (let x in this.qa_status_list) {
        if (this.qa_status_list[x].id === this.statusFilter) {
          v = this.qa_status_list[x].label
        }
      }
      return v
    },
    stati: function () {
      if (this.isQAOfficer) {
        return this.isGnhr ? [6, 3, 5] : [1, 2, 3]
      } else if (!this.isGnhr) {
        return [0, 1, 2, 4]
      }
      return [0, 1, 2, 3, 4, 6, 5]
    },
    showErrorFilter: function () {
      return this.statusFilter == 1 || this.statusFilter == 2 || this.statusFilter == 3
    },
    showBulk: function () {
      return is.inArray(this.statusFilter, [0, 1]) && this.canEdit && (!this.isGnhr || this.isQAAdmin)
    },
    showGBulk: function () {
      return this.isGnhr && (this.isQAAdmin || this.isSuperAdmin) && is.inArray(this.statusFilter, [4, 6])
    },
    visibleTabs: function () {
      let self = this
      let x = this.tabs.filter(function (f) {
        return self.listFilter === 'all' || f.has_errors
      })
      return x
    },
    officerAssignDisabled: function () {
      return this.assign_officer_id === 0 || is.null(this.assign_officer_id)
    },
    gofficerAssignDisabled: function () {
      return this.assign_gofficer_id === 0 || is.null(this.assign_gofficer_id)
    },
    canEdit: function () {
      return this.isGnhr ? is.inArray(this.statusFilter, [3, 5, 6]) : true
    },
    pageText: function () {
      let size = is.nan(this.pageSize) || is.undefined(this.pageSize) ? 150 : this.pageSize
      let page = is.nan(this.currentPage) || is.undefined(this.currentPage) ? 1 : this.currentPage
      let end = size * page
      let start = end - size + 1
      let endText = end > this.totalRows ? this.totalRows : end
      return this.totalRows === 0 ? '0' : start + ' - ' + endText + ' of ' + this.totalRows
    }
  },
  methods: {
    // API Call handlers
    get() {
      if (!this.busy) {
        let self = this
        this.busy = true
        let params = this.getParams()
        surveyService
          .getHouseholds(params)
          .then((response) => {
            self.procResults(response)
          })
          .catch((error) => {
            this.busy = false
            this.handleError(error)
          })
      }
    },
    loadStatsData() {
      const self = this
      let params = this.getParams()

      Object.keys(this.stats).forEach(function (x) {
        params['qa_status'] = self.getStatusById(self.stats[x].id)
        self.stats[x].loading = true
        surveyService
          .getQAStats(params)
          .then((i) => {
            let total = i.data['total']
            self.stats[x].loading = false
            self.stats[x].value = self.cnumbers(total)
          })
          .catch((error) => {
            // self.stats[x].loading = false
            // self.stats[x].value = 0
            // eslint-disable-next-line no-console
            console.log(error)
          })
      })
    },
    doOfficerAssign() {
      let self = this
      let payload = {
        field: 'officer',
        officer: this.assign_officer_id,
        groups: this.getSelectedGroups()
      }
      surveyService
        .updateHouseholdQA(payload)
        .then(() => {
          self.assign_officer_id = 0
          self.assignDialogVisible = false
          self.showSuccess('Success', 'Officers updated')
          self.get()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    doGOfficerAssign() {
      let self = this
      let payload = {
        field: 'gofficer',
        gofficer: this.assign_gofficer_id,
        groups: this.getSelectedGroups()
      }
      surveyService
        .updateHouseholdQA(payload)
        .then(() => {
          self.assign_gofficer_id = 0
          self.assignGQADialogVisible = false
          self.showSuccess('Success', 'Officers assigned')
          self.get()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    sendForCorrection(payload) {
      let self = this
      surveyService
        .updateHouseholdQA(payload)
        .then(() => {
          self.showSuccess('Success', 'Status updated')
          self.get()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    // Event Handlers
    assignOfficer(idx) {
      this.visibleTabs[idx].selected = true
      this.assignDialogVisible = true
    },
    assignGOfficer(idx) {
      this.visibleTabs[idx].selected = true
      this.assignGQADialogVisible = true
    },
    sendBack(idx, row) {
      this.visibleTabs[idx].selected = true
      this.doSendBack()
    },
    nextHousehold() {
      this.get()
    },
    handleBulkAction(val) {
      let o = this.getSelectedGroups()
      if (o.length <= 0) {
        this.$message({
          type: 'warning',
          message: 'Please first select rows you want to act on.'
        })
      } else {
        if (val === 'return') {
          this.doSendBack()
        } else if (val === 'assign') {
          this.assignDialogVisible = true
        } else if (val === 'assignGnhrQA') {
          this.assignGQADialogVisible = true
        }
      }
    },
    handleFilterAction(val) {
      this.listFilter = val
    },
    movePage(val) {
      this.currentPage = val
      this.get()
    },
    filterByStatus(v) {
      this.reset(false)
      this.statusFilter = v.value
      this.get()
    },
    filterByOfficer(v) {
      this.reset()
      this.officerFilter = v.value
      this.get()
      this.loadStatsData()
    },
    filterByFirm(v) {
      this.reset()
      this.firmFilter = v.value
      this.get()
      this.loadStatsData()
    },
    filterByEnumerator(v) {
      this.reset()
      this.enumeratorFilter = v.value
      this.get()
      this.loadStatsData()
    },
    filterByRegion(v) {
      this.reset()
      this.regionFilter = v.value
      this.get()
      this.loadStatsData()
    },
    filterByDistrict(v) {
      this.reset()
      this.districtFilter = v.value
      this.get()
      this.loadStatsData()
    },
    filterByCommunity(v) {
      this.reset()
      this.communityFilter = v.value
      this.get()
      this.loadStatsData()
    },
    tabsClicked(newTabIdx) {
      this.visibleTabs[newTabIdx].selected = !this.visibleTabs[newTabIdx].selected
      this.canUpdateBulk = false
    },
    tabClicked() {
      if (this.canUpdateBulk) {
        this.visibleTabs[this.activeTab].selected = !this.visibleTabs[this.activeTab].selected
      } else {
        this.canUpdateBulk = true
      }
    },
    // Helpers
    procResults(response) {
      let self = this
      let rawData = response.data
      this.errLen = response.meta.num_errors
      this.totalRows = response.meta.total

      this.tabs = rawData.map(function (t) {
        self.errLen += t.num_errors
        t.selected = false
        return t
      })
      //.sort((a, b) => (a.name > b.name ? 1 : -1))

      this.busy = false
    },
    getParams() {
      let info = this.queryProps
      return {
        use_sdate: 0,
        spotcheck: 0,
        page: this.currentPage,
        limit: this.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : this.sortProps.prop,
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : this.sortProps.order,
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        enumerator: this.enumeratorFilter === 0 ? '' : this.enumeratorFilter,
        region: this.regionFilter === 0 ? '' : this.regionFilter,
        district: this.getDistrict(),
        community: this.getCommunity(),
        household: this.household === 0 ? '' : this.household,
        gofficer: this.isGnhr ? (this.officerFilter === 0 ? (this.isQAAdmin || this.isAdmin ? '' : this.user.userID) : this.officerFilter) : '',
        officer: this.isGnhr ? '' : this.officerFilter === 0 ? (this.isQAAdmin || this.isAdmin ? '' : this.user.userID) : this.officerFilter,
        search: this.searchFilter === '' ? '' : this.searchFilter,
        qa_status: this.getStatus(),
        version: 2
      }
    },
    setSelected(select) {
      let self = this
      if (this.showBulk || this.showGBulk) {
        this.visibleTabs.forEach(function (x, i) {
          self.visibleTabs[i].selected = select
        })
      }
    },
    getSelectedGroups() {
      let self = this
      let o = []
      if (this.showBulk || this.showGBulk) {
        this.visibleTabs.forEach(function (x, i) {
          if (x.selected) {
            o.push(self.visibleTabs[i].id)
          }
        })
      } else {
        o.push(this.visibleTabs[this.activeTab].id)
      }
      return o
    },
    getEnumerator(data) {
      return data.enumerator
    },
    getOfficer(data) {
      return data.officer
    },
    getGOfficer(data) {
      return data.gofficer
    },
    getStatus() {
      let v = [1]
      for (let x in this.qa_status_list) {
        if (this.qa_status_list[x].id === this.statusFilter) {
          v = this.qa_status_list[x].sid
        }
      }
      return v
    },
    getStatusById(id) {
      let v = [1]
      for (let x in this.qa_status_list) {
        if (this.qa_status_list[x].id === id) {
          v = this.qa_status_list[x].sid
        }
      }
      return v
    },
    doSendBack() {
      let self = this
      this.$prompt('Please provide reason for rejecting this entry?', 'Rejecting Entry response', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^\s*\S+(?:\s+\S+){2,}\s*$/,
        inputErrorMessage: 'Reason must be 3 or more words'
      })
        .then((value) => {
          let payload = {
            field: 'status',
            status: 15,
            reason: value.value,
            groups: self.getSelectedGroups()
          }
          self.sendForCorrection(payload)
        })
        .catch(() => {
          self.$message({
            type: 'info',
            message: 'Rejection canceled'
          })
        })
    },
    reset(inclStats = true) {
      let self = this
      this.csvData = []
      this.errLen = 0
      this.seen = []
      if (inclStats) {
        Object.keys(this.stats).forEach(function (x) {
          self.stats[x].value = 0
        })
      }
    },
    csvExport() {
      let self = this
      self.csvLoading = true
      surveyService
        .getErrorsForDownload(this.getCsvParams())
        .then((response) => {
          let csvContent = 'data:text/csv;charset=utf-8,Enumerator,District,Community,Error Code,Question,Answer,Message,Date\n'
          csvContent += response
            .map(function (d) {
              let desc = is.null(d.descriptions) || is.empty(d.descriptions) ? '' : d.descriptions.replace(',', '')
              let resp = is.null(d.response) || is.empty(d.response) ? '' : d.response.replace(',', '')
              let info = is.null(d.addInfo) || is.empty(d.addInfo) ? '' : d.addInfo.replace(',', '')
              return d.enumerator + ',' + d.district + ',' + d.community + ',' + d.code + ',' + desc + ',' + resp + ',' + info + ',' + d.errorDate
            })
            .join('\n')
            .replace(/(^\{)|(\}$)/gm, '')
          let encodedUri = encodeURI(csvContent)
          let link = document.createElement('a')
          link.setAttribute('href', encodedUri)
          link.setAttribute('download', 'QA Errors.csv')
          document.body.appendChild(link)
          link.click()
          self.csvLoading = false
        })
        .catch((error) => {
          this.busy = false
          self.csvLoading = false
          this.handleError(error)
        })
    },
    getCsvParams() {
      return {
        use_new: 1,
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        enumerator: this.enumeratorFilter === 0 ? '' : this.enumeratorFilter,
        region: this.regionFilter === 0 ? '' : this.regionFilter,
        district: this.getDistrict(),
        community: this.getCommunity(),
        officer: this.officerFilter === 0 ? (this.isQAAdmin || this.isAdmin ? '' : this.user.userID) : this.officerFilter
      }
    },
    getDistrict() {
      let region = this.regionFilter === 0 ? '' : this.regionFilter
      let dist = this.districtFilter === 0 ? '' : this.districtFilter

      if (region !== '' && dist !== '') {
        dist = dist.substring(0, 2) == region ? dist : ''
      }

      return dist
    },
    getCommunity() {
      let district = this.districtFilter === 0 ? '' : this.districtFilter
      let comm = this.communityFilter === 0 ? '' : this.communityFilter

      if (district !== '' && comm !== '') {
        comm = comm.substring(0, 5) == district ? comm : ''
      }

      return comm
    },
    // Error uploads
    handleErrorAction(val) {
      if (val === 'upload') {
        this.errorDialogVisible = true
      } else if (val === 'download') {
        this.downloadErrorTemplate()
      }
    },
    downloadErrorTemplate() {
      let self = this
      let csvContent = 'data:text/csv;charset=utf-8'
      for (let x in this.bulkErrorFields) {
        csvContent += ',' + this.bulkErrorFields[x]
      }
      // csvContent += '\n'
      // csvContent += this.roles
      //   .map(function (d) {
      //     return self.userFirm + ',' + d.role
      //   })
      //   .join('\n')
      //   .replace(/(^\{)|(\}$)/gm, '')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'GNHR QA Error Template.csv')
      document.body.appendChild(link)
      link.click()
    },
    uploadErrorSuccess() {
      this.$message({ type: 'success', message: 'Upload successful.' })
      this.bulkErrorCsv = []
      this.errorDialogVisible = false
      this.reset()
      this.get()
      this.loadStatsData()
    },
    uploadErrorFailed(e) {
      let msg = 'An error occured.'
      if (is.array(e.response.data.error)) {
        msg = e.response.data.error.join('<br><br/>')
      } else {
        msg = e.response.data.error
      }
      msg = 'Error uploading file:<br><br>' + msg
      this.$message({ dangerouslyUseHTMLString: true, type: 'error', message: msg, duration: 5000 })
    },
    handleErrorClose(done) {
      if (is.function(done)) {
        done()
      }
      this.bulkErrorCsv = []
      this.errorDialogVisible = false
    }
  }
}
</script>
<style>
.scrollable-nav-tabs {
  flex-wrap: nowrap !important;
  white-space: nowrap;
  max-height: 800px;
  overflow: auto;
}
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
.stats-small {
  cursor: pointer;
}
</style>
